import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import TitleSection from "../components/titleSection"
import FeatureSection from "../components/featureSection"
import ImageSection from "../components/imageSection"
import QuoteSection from "../components/quoteSection"
import FooterPostList from "../components/footerPostList"
import FooterSection from "../components/footerSection"

const IndexPage = props => {
  const data = props.data.file.childMarkdownRemark.frontmatter

  return (
    <Layout>
      <SEO title="Home" />
      <TitleSection
        heading={data.hero_heading}
        body={data.hero_body}
        image={data.hero_image.childImageSharp.fluid}
      />
      <FeatureSection
        headingOne={data.feature_1_heading}
        bodyOne={data.feature_1_body}
        headingTwo={data.feature_2_heading}
        bodyTwo={data.feature_2_body}
        headingThree={data.feature_3_heading}
        bodyThree={data.feature_3_body}
      />
      <ImageSection
        heading={data.sports_heading}
        body={data.sports_body}
        image={data.sports_image.childImageSharp.fluid}
      />
      <QuoteSection
        imageOne={data.quote_1_image.childImageSharp.fluid}
        nameOne={data.quote_1_name}
        companyOne={data.quote_1_company}
        quoteOne={data.quote_1_body}
        imageTwo={data.quote_2_image.childImageSharp.fluid}
        nameTwo={data.quote_2_name}
        companyTwo={data.quote_2_company}
        quoteTwo={data.quote_2_body}
        imageThree={data.quote_3_image.childImageSharp.fluid}
        nameThree={data.quote_3_name}
        companyThree={data.quote_3_company}
        quoteThree={data.quote_3_body}
      />
      <FooterPostList heading={data.posts_label} />
      <FooterSection image={data.hero_image.childImageSharp.fluid} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    file(name: { eq: "home" }) {
      childMarkdownRemark {
        frontmatter {
          hero_heading
          hero_body
          hero_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          feature_1_heading
          feature_1_body
          feature_2_heading
          feature_2_body
          feature_3_heading
          feature_3_body
          sports_heading
          sports_body
          sports_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          quote_1_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 360) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          quote_1_name
          quote_1_company
          quote_1_body
          quote_2_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 360) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          quote_2_name
          quote_2_company
          quote_2_body
          quote_3_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 360) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          quote_3_name
          quote_3_company
          quote_3_body
          posts_label
        }
      }
    }
  }
`
