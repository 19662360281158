import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import FooterPostItem from "./footerPostItem"

import cx from "classnames"
import styles from "./footerPostList.module.scss"

const FooterPostList = props => {
  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fields: { sourceInstanceName: { eq: "posts" } }
          frontmatter: { published: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "MMM DD, YYYY")
              featured_image {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 900) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const filter = query.allMarkdownRemark.edges
    .filter(post => post.node.id !== props.currentPost)
    .slice(0, 3)

  const display = () =>
    filter.map(post => <FooterPostItem key={post.node.id} post={post.node} />)

  return (
    <section className={cx("wrapper full-width", styles.section)}>
      <div className={cx("wrapper page-width", styles.wrapper)}>
        <div className="flex align-center justify-between">
          <h2 className={styles.heading}>{props.heading}</h2>
          <Link className={styles.button} to="/posts/">
            Read more
          </Link>
        </div>
        <div className="flex align-stretch justify-between">{display()}</div>
      </div>
    </section>
  )
}

FooterPostList.defaultProps = {
  currentPost: "none",
}

export default FooterPostList
