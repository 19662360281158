import React from "react"
import Img from "gatsby-image"

import CallToAction from "./callToAction"

import cx from "classnames"
import styles from "./imageSection.module.scss"

const ImageSection = ({ heading, body, image }) => (
  <section className="wrapper page-width-left flex align-stretch justify-between">
    <Img className={styles.image} fluid={image} />
    <div className={cx("flex column justify-center", styles.text)}>
      <h2>{heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
      <CallToAction />
    </div>
  </section>
)

export default ImageSection
