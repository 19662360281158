import React from "react"
import { Link } from "gatsby"

import cx from "classnames"
import styles from "./footerPostItem.module.scss"

const FooterPostItem = ({ post }) => (
  <Link
    className={cx("flex column justify-center", styles.wrapper)}
    to={post.fields.slug}
  >
    <h3 className={styles.title}>{post.frontmatter.title}</h3>
  </Link>
)

export default FooterPostItem
