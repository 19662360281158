import React from "react"

import styles from "./featureSection.module.scss"

const FeatureSection = ({
  headingOne,
  bodyOne,
  headingTwo,
  bodyTwo,
  headingThree,
  bodyThree,
}) => (
  <section className="wrapper section page-width flex align-start justify-between">
    <div className={styles.feature}>
      <h2>{headingOne}</h2>
      <div dangerouslySetInnerHTML={{ __html: bodyOne }}></div>
    </div>
    <div className={styles.feature}>
      <h2>{headingTwo}</h2>
      <div dangerouslySetInnerHTML={{ __html: bodyTwo }}></div>
    </div>
    <div className={styles.feature}>
      <h2>{headingThree}</h2>
      <div dangerouslySetInnerHTML={{ __html: bodyThree }}></div>
    </div>
  </section>
)

export default FeatureSection
