import React from "react"
import Img from "gatsby-image"

import cx from "classnames"
import styles from "./quoteSection.module.scss"

const QuoteSection = ({
  imageOne,
  nameOne,
  companyOne,
  quoteOne,
  imageTwo,
  nameTwo,
  companyTwo,
  quoteTwo,
  imageThree,
  nameThree,
  companyThree,
  quoteThree,
}) => (
  <section className="wrapper section page-width flex align-start justify-between">
    <div className={cx("flex align-center justify-between", styles.quote)}>
      <Img alt={nameOne} className={styles.image} fluid={imageOne} />
      <div className={styles.text}>
        <h2>
          {nameOne}
          <br></br>
          <span>{companyOne}</span>
        </h2>
        <p>
          <span>“&nbsp;</span>
          {quoteOne}
        </p>
      </div>
    </div>
    <div className={cx("flex align-center justify-between", styles.quote)}>
      <Img alt={nameTwo} className={styles.image} fluid={imageTwo} />
      <div className={styles.text}>
        <h2>
          {nameTwo}
          <br></br>
          <span>{companyTwo}</span>
        </h2>
        <p>
          <span>“&nbsp;</span>
          {quoteTwo}
        </p>
      </div>
    </div>
    <div className={cx("flex align-center justify-between", styles.quote)}>
      <Img alt={nameThree} className={styles.image} fluid={imageThree} />
      <div className={styles.text}>
        <h2>
          {nameThree}
          <br></br>
          <span>{companyThree}</span>
        </h2>
        <p>
          <span>“&nbsp;</span>
          {quoteThree}
        </p>
      </div>
    </div>
  </section>
)

export default QuoteSection
