import React from "react"
import Img from "gatsby-image"

import CallToAction from "./callToAction"

import cx from "classnames"
import styles from "./titleSection.module.scss"

import logo from "../images/pdb-logo-min-main.svg"

const TitleSection = ({ heading, body, image }) => (
  <section className="wrapper page-width-right flex align-stretch justify-between">
    <div className={cx("flex column justify-center", styles.text)}>
      <img alt="PdB Consulting logo." className={styles.logo} src={logo} />
      <h1>{heading}</h1>
      <div dangerouslySetInnerHTML={{ __html: body }}></div>
      <CallToAction />
    </div>
    <Img className={styles.image} fluid={image} />
  </section>
)

export default TitleSection
