import React, { useEffect, useState } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"

import styles from "./callToAction.module.scss"

const CallToAction = ({ children, desktopLink, mobileLink }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
      ? setIsMobile(true)
      : setIsMobile(false)
  }, [])

  if (isMobile === true) {
    return (
      <a className={styles.link} href={mobileLink}>
        {children}
      </a>
    )
  } else {
    return (
      <AnchorLink className={styles.link} href={desktopLink}>
        {children}
      </AnchorLink>
    )
  }
}

CallToAction.defaultProps = {
  children: "Let's talk.",
  desktopLink: "#footer",
  mobileLink: "tel:021432022",
}

export default CallToAction
